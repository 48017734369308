import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {  StyledInput, StyledModal, StyledBody, StyledHeader, Container } from '../AddWellnessInitiative/styles';
import { imgPath } from '../../utils/constants';
import { SubmitButton, ErrorContainer, SubmitContainer, ForgetImg , PasswordContent } from './styles';
import { ErrorMessage } from '../LoginPage/styles';
import { isValidEmail } from '../../utils/methods';

import { forgetPassword } from '../../redux/actions';
import {LOGO_URL} from '../../utils/config';
class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkMail: false,
      mailId: '',
      errorMsg: ''
    }
  }

  resetPasswordAction = (e) => {
    e.preventDefault();
    const { mailId, checkMail } = this.state;
    const { forgetPassword, onClose } = this.props;
    if(checkMail) {
      let obj = {};
      obj['email'] = mailId;
      forgetPassword(obj);
      this.setState({
        mailId: '',
        checkMail: false
      });
      onClose();
    }
  };
 
  changInput = (e) => {
    if(e.target.value.trim().length<50) {
      this.setState({
        [e.target.name]: e.target.value.trim()
      },() => {
        let checkMail = isValidEmail(this.state.mailId);
        this.setState({
          checkMail: checkMail
        },() => {
          if(!checkMail) {
            this.setState({
              errorMsg: 'Invalid Email'
            })
          }
          else {
            this.setState({
              errorMsg: ''
            })
          }
        })
      })
    }
  };

  onClosePopup = () => {
    this.setState({
      checkMail: false,
      mailId: '',
      errorMsg: ''
    },() => {
      this.props.onClose();
    })
  }

  render() {
    const { showModal } = this.props;
    const { mailId, checkMail, errorMsg } = this.state;
    return(
      <StyledModal show={showModal} onHide={this.onClosePopup}>
        <StyledHeader closeButton>
          <Container>
            <ForgetImg>
              <img src={imgPath+`${LOGO_URL ? '/Logo/woliba_trans.png' : '/training-amigo-logo.png'}`}
                alt="training-amigo-logo"
                onClick={() => this.props.history.push('/')}
              />
            </ForgetImg>
          </Container>
        </StyledHeader>
        <StyledBody>
          <PasswordContent>
            Request New Password
          </PasswordContent>
          <ErrorContainer>
            <ErrorMessage>{errorMsg}</ErrorMessage>
          </ErrorContainer>
          <form onSubmit={this.resetPasswordAction}>
            <SubmitContainer>              
              <StyledInput placeholder="Email" name="mailId" value={mailId} onChange={(e) => this.changInput(e)}/>
            </SubmitContainer>         
            <SubmitContainer>
              <SubmitButton 
                type="submit"
                float={1} 
                Align={1}  
                width= "150px" 
                radius="5px" 
                fontSize="13px" 
                bgColor={'#159fc9'}
                disabled={!checkMail}
              >
                Submit
              </SubmitButton>  
            </SubmitContainer>
          </form>
        </StyledBody>
      </StyledModal>
    );
  }
}

ForgetPassword.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  forgetPassword: PropTypes.func,
  history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  forgetPassword: (data) => dispatch(forgetPassword(data))
});

export default connect(null, mapDispatchToProps)(ForgetPassword);