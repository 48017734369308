import styled from 'styled-components';
const BoldFont = 'Rubik-Bold';
import { UserButton } from '../EngagementSection/styles';

const ForgetImg = styled.div`
  text-align: center;
  margin-top: 20px;
  > img {
    width: 100px;
    cursor: pointer;
  }
`;

const PasswordContent = styled.div`
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: ${BoldFont};
  margin-top: 20px;
`;

const SubmitContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const ErrorContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
  }
  > li {
    display: block;
    margin-left: 6px;
  }
`;

const Error = styled.div`
  width: 100%;
  margin-left: 0px;
> li {
  display: block;
  margin-left: 5px;
  margin-bottom: 20px;
}
`;

const SubmitButton = styled(UserButton)`
  cursor: ${({disabled}) => disabled ? 'not-allowed !important' : 'pointer'};
  opacity: ${({disabled}) => disabled && '0.5'};
`;

export { SubmitButton, Error, ErrorContainer, ForgetImg, PasswordContent, SubmitContainer };
